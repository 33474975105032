<template>
  <div>
    <el-card >
      <template #header>
        <span style="font-size: small">{{$t('msg.track.basicInfo')}}</span>
        <el-button type="primary" size="small" @click="addRecord" style="float: right" disabled>{{ $t('msg.basicInfo.addRecord') }}</el-button>
      </template>
      <el-table :data="draySynthesisList" style="font-size: smaller">
<!--        <el-table-column prop="primeKey" :label="$t('msg.rate.primeKey')" sortable align="center"></el-table-column>-->
        <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')" sortable align="center"></el-table-column>
        <el-table-column prop="pod" :label="$t('msg.rate.pod')" sortable align="center"></el-table-column>
        <el-table-column prop="eta" :label="$t('msg.track.eta')" sortable align="center"></el-table-column>
        <el-table-column prop="swStatus" :label="$t('msg.track.swStatus')" sortable align="center"></el-table-column>
        <el-table-column prop="normTotal" :label="$t('msg.track.normTotal')" sortable align="center">
          <template #default="scope">
            <span >$ {{scope.row.normTotal}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payStatus" :label="$t('msg.account.payStatus')" sortable align="center"></el-table-column>
        <el-table-column :label="$t('msg.rate.Operations')" align="center" width="150px">
          <template #default="{row}">
            <el-button type="primary" @click="detail(row)" size="small" v-if="row.normTotal!==0">Detail</el-button>
            <el-button type="danger" @click="editInfo(row)" size="small" v-else>Add Data</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="Quote Basic Information" v-model="orderFormShow" v-on:close="resetForm()" width="30%" center draggable>
      <el-form :data="orderFormData" :label-position="orderLabelPosition" inline>
        <el-form-item :label="$t('msg.rate.clientName')" prop="clientName" required>
          <el-select v-model="orderFormData.clientName" value-key="name" placeholder="Select" @change="getPayTerm">
            <el-option v-for="item in clientList" :key="item.name" :label="item.name" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.payTerm')" prop="payTerm">
          <el-input v-model="orderFormData.payTerm" disabled></el-input>
        </el-form-item>
        <el-form-item prop="streetOne" :label="$t('msg.address.streetOne')">
          <el-input v-model="orderFormData.streetOne" :disabled="addressImportDisable"></el-input>
        </el-form-item>
        <el-form-item prop="streetTwo" :label="$t('msg.address.streetTwo')">
          <el-input v-model="orderFormData.streetTwo" :disabled="addressImportDisable"></el-input>
        </el-form-item>
        <el-form-item prop="city" :label="$t('msg.address.city')">
          <el-input v-model="orderFormData.city" :disabled="cityInputDisable"></el-input>
        </el-form-item>
        <el-form-item prop="state" :label="$t('msg.address.state')">
          <el-input v-model="orderFormData.state" :disabled="addressImportDisable"></el-input>
        </el-form-item>
        <el-form-item prop="zip" :label="$t('msg.address.zip')">
          <el-input v-model="orderFormData.zip" :disabled="addressImportDisable"></el-input>
        </el-form-item>
        <el-button type="text" @click="getAddress()" style="margin-top: 30px" :disabled="addressImportDisable">{{$t('msg.address.importAddress')}}</el-button>
      </el-form>
      <el-divider></el-divider>
      <el-button type="primary" v-on:click="createOrder()" style="float: right;margin-bottom: 20px">Submit Data</el-button>
    </el-dialog>
    <el-dialog title="My Address Book" v-model="addressVisible" width="60%" center draggable>
      <el-button type="primary" style="float: right;margin-bottom: 20px;margin-right: 40px" @click="createAddress()">{{$t('msg.address.newAddress')}}</el-button>
      <el-table :data="myAddress">
        <el-table-column prop="personName" :label="$t('msg.address.personName')" size="small"></el-table-column>
        <el-table-column prop="streetOne" :label="$t('msg.address.streetOne')" size="small"></el-table-column>
        <!--        <el-table-column prop="streetTwo" :label="$t('msg.address.streetTwo')"></el-table-column>-->
        <el-table-column prop="city" :label="$t('msg.address.city')" size="small"></el-table-column>
        <el-table-column prop="state" :label="$t('msg.address.state')" size="small"></el-table-column>
        <el-table-column prop="zip" :label="$t('msg.address.zip')" size="small"></el-table-column>
        <el-table-column fixed="right" :label="$t('msg.rate.Operations')" align="center">
          <template #default="scope">
            <el-button link type="text" size="small" @click="importRow(scope.row)">{{$t('msg.address.importAddress')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="New Address" v-model="newAddressVisible" width="30%" center draggable>
      <el-form :data="newAddress">
        <el-form-item prop="personName" :label="$t('msg.address.personName')" style="width: 20pc">
          <el-input v-model="newAddress.personName"></el-input>
        </el-form-item>
        <el-form-item prop="streetOne" :label="$t('msg.address.streetOne')" style="width: 20pc">
          <el-input v-model="newAddress.streetOne"></el-input>
        </el-form-item>
        <el-form-item prop="city" :label="$t('msg.address.city')" style="width: 20pc" >
          <el-input v-model="newAddress.city" disabled></el-input>
        </el-form-item>
        <el-form-item prop="state" :label="$t('msg.address.state')" style="width: 20pc">
          <el-select v-model="newAddress.state" placeholder="Select">
            <el-option v-for="item in states" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item prop="zip" :label="$t('msg.address.zip')" style="width: 20pc">
          <el-input v-model="newAddress.zip"></el-input>
        </el-form-item>
        <el-button type="primary" style="float: right;margin-bottom: 20px;margin-right: 20px" @click="insertAddress()">Submit</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'drayManage',
  setup() {
    const orderLabelPosition = ref('top')
    const addressImportDisable = ref(false)
    const cityInputDisable = ref(true)
    const myAddress = ref([])
    const addressVisible = ref(false)
    const curUser = localStorage.getItem('curUser')
    const draySynthesisList = ref()
    const newAddressVisible = ref(false)
    const newAddress = ref({ personName: '', streetOne: '', city: '', state: '', zip: '' })
    const states = ref([])
    const clientList = ref([])
    const orderFormShow = ref(false)
    const orderFormData = ref({ clientName: '', payTerm: '', primeKey: '', clientTag: '', streetOne: '', streetTwo: '', city: '', state: '', zip: '', zeroSumFlag: 'n' })
    const router = useRouter()
    const getDraySynList = () => {
      const param = 'curUser=' + curUser
      const reqURL = process.env.VUE_APP_BASE + '/dray/getDraySynList'
      axios.post(reqURL, param).then((response) => {
        draySynthesisList.value = response.data
      })
    }
    onMounted(() => {
      getDraySynList()
    })
    const getStates = () => {
      axios.get(process.env.VUE_APP_BASE + '/getUS50States').then((response) => {
        states.value = response.data
      })
    }
    const getAddress = () => {
      const param = 'curUser=' + curUser + '&city=' + orderFormData.value.city
      const reqURL = process.env.VUE_APP_BASE + '/getAddressByCity'
      axios.post(reqURL, param).then((response) => {
        myAddress.value = response.data
        addressVisible.value = true
      }).catch((response) => {
        console.log(response)
      })
    }
    const insertAddress = () => {
      if (newAddress.value.personName === '' || newAddress.value.streetOne === '' || newAddress.value.state === '' || newAddress.value.zip === '') {
        alert('Every field in this form is required!')
        return false
      }
      const streetOne = newAddress.value.streetOne.replaceAll(',', ' ')
      const param = 'curUser=' + curUser + '&city=' + newAddress.value.city + '&personName=' + newAddress.value.personName + '&streetOne=' + streetOne +
        '&state=' + newAddress.value.state.split('-')[1] + '&zip=' + newAddress.value.zip
      const reqURL = process.env.VUE_APP_BASE + '/insertAddress'
      axios.post(reqURL, param).then((response) => {
        myAddress.value = response.data
        newAddressVisible.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    const createAddress = () => {
      getStates()
      newAddress.value.city = orderFormData.value.city
      newAddressVisible.value = true
    }
    const importRow = (row) => {
      orderFormData.value.streetOne = row.streetOne
      orderFormData.value.city = row.city
      orderFormData.value.state = row.state
      orderFormData.value.zip = row.zip
      addressVisible.value = false
    }
    const getClientList = () => {
      const param = 'curName=' + localStorage.getItem('curUser')
      const reqURL = process.env.VUE_APP_BASE + '/ocean/getUniqueClientName'
      axios.post(reqURL, param).then((response) => {
        clientList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const getPayTerm = (val) => {
      orderFormData.value.clientTag = val.name + '-' + val.attr.split('-')[0]
      const param = 'clientCode=' + val.attr.split('-')[0]
      const reqURL = process.env.VUE_APP_BASE + '/getPayTermByClientCode'
      console.log(param)
      axios.post(reqURL, param).then((response) => {
        orderFormData.value.payTerm = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const editInfo = (row) => {
      if (row.desType === 'FBA') {
        const param = 'fbaCode=' + row.toArea
        const reqURL = process.env.VUE_APP_BASE + '/getAddressByFBAcode'
        axios.post(reqURL, param).then((response) => {
          console.log(response.data)
          orderFormData.value.streetOne = response.data.streetOne
          orderFormData.value.city = response.data.city
          orderFormData.value.state = response.data.state
          orderFormData.value.zip = response.data.zip
          addressImportDisable.value = true
        }).catch((response) => {
          console.log(response)
        })
      } else {
        addressImportDisable.value = false
        orderFormData.value.city = row.toArea
      }
      getClientList()
      orderFormData.value.primeKey = row.primeKey
      if (row.normTotal === 0) {
        orderFormData.value.zeroSumFlag = 'y'
        addressImportDisable.value = false
        cityInputDisable.value = false
      } else {
        orderFormData.value.zeroSumFlag = 'n'
      }
      orderFormShow.value = true
    }
    const createOrder = () => {
      if (orderFormData.value.clientTag === '' || orderFormData.value.streetOne === '' || orderFormData.value.city === '' || orderFormData.value.state === '' || orderFormData.value.zip === '') {
        alert('clientName,StreetOne,city,state,zip are required!')
        return false
      }
      const refId = orderFormData.value.primeKey
      const param = 'primeKey=' + refId + '&payTerm=' + orderFormData.value.payTerm + '&curUser=' + curUser +
        '&clientTag=' + orderFormData.value.clientTag + '&streetOne=' + orderFormData.value.streetOne + '&streetTwo=' + orderFormData.value.streetTwo +
        '&city=' + orderFormData.value.city + '&state=' + orderFormData.value.state + '&zip=' + orderFormData.value.zip + '&zeroSumFlag=' + orderFormData.value.zeroSumFlag
      const reqURL = process.env.VUE_APP_BASE + '/dray/createOrderByPrimeKey'
      axios.post(reqURL, param).then((response) => {
        if (response.data === 'Successful') {
          alert(response.data)
          getDraySynList()
          router.push(`/dray/detail/${refId}`)
        } else {
          alert(response.data)
          return false
        }
      }).catch((response) => {
        console.log(response)
      })
      orderFormShow.value = false
    }
    const detail = (row) => {
      const refId = row.primeKey
      if (row.payStatus === 'NA') {
        editInfo(row)
      } else {
        router.push(`/dray/detail/${refId}`)
      }
    }
    const resetForm = () => {
      orderFormData.value = { clientName: '', payTerm: '', primeKey: '', clientTag: '', streetOne: '', streetTwo: '', city: '', state: '', zip: '', zeroSumFlag: 'n' }
    }
    return { getDraySynList, insertAddress, clientList, getClientList, getPayTerm, importRow, createAddress, createOrder, editInfo, cityInputDisable, newAddressVisible, states, newAddress, addressVisible, myAddress, orderLabelPosition, addressImportDisable, orderFormData, curUser, draySynthesisList, detail, resetForm, getAddress, orderFormShow }
  }
})
</script>
